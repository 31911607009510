import React from "react"
import Layout from "../../components/layout"
import CommentsFB from "../../components/Comments"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const Poemas = () => {
  return (
    <Layout>
      <GatsbySeo
        title="Es primavera - Poemas"
        titleTemplate="%s | Eduardo Ceballos"
        description="Es primavera"
        canonical="https://eduardoceballos.com/poemas/es-primavera"
        openGraph={{
          url: "https://eduardoceballos.com/poemas/es-primavera",
          title: "Es primavera - Poemas",
          description: "Es primavera",
          images: [
            {
              url: "https://eduardoceballos.com/seo/poemas.jpg",
              alt: "Poemas",
            },
          ],
          site_name: "Eduardo Ceballos",
        }}
        twitter={{
          handle: "@PoetadeSalta",
          site: "@PoetadeSalta",
          cardType: "summary_large_image",
        }}
      />
      <h1>Es primavera</h1>
      <center>
        <p>
          {" "}
          <br />
          Volvió la flor.
          <br />
          El verde se instala en el árbol
          <br />
          con su fragancia nueva.
          <br />
          &nbsp;
          <br />
          Es primavera,
          <br />
          la luz abarca el paisaje
          <br />
          con maestría antigua.
          <br />
          &nbsp;
          <br />
          Cierro los ojos, abro la inteligencia
          <br />y percibo el trabajo de la semilla,
          <br />
          dialoga con la tierra,
          <br />
          como si se conocieran desde siempre.
          <br />
          &nbsp;
          <br />
          El tiempo con manos mágicas
          <br />
          mezcla químicas, historias
          <br />
          construyendo esta realidad frutal
          <br />
          donde convergen todas las partículas.
          <br />
          &nbsp;
          <br />
          Es primavera, todo se emparenta.
          <br />
          &nbsp;
          <br />
          Un milagro de sol viaja con los pájaros
          <br />
          por el alto océano del espacio,
          <br />
          un canto trigo de luna nueva
          <br />
          danza con el río, que vive la fiesta.
          <br />
          &nbsp;
          <br />
          Con la alcancía de mis ojos,
          <br />
          pinto sueños que asoman los paisajes,
          <br />
          que bebe de un sorbo el caballo salvaje.
          <br />
          &nbsp;
          <br />
          Bajo a tientas por el árbol,
          <br />
          mientras su cauce sube
          <br />
          con la líquida carga que viene de la tierra,
          <br />
          produciendo el fruto del sabor justo.
          <br />
          &nbsp;
          <br />
          Es primavera también en el río de mi sangre.
          <br />
          &nbsp;
          <br />
          Como una afinada sinfónica
          <br />
          los sentidos ponen la música
          <br />
          que se reparte en sonrisas por el aire.
          <br />
          &nbsp;
          <br />
          La vida recupera su plenitud,
          <br />
          enérgica alegría la invade.
          <br />
          &nbsp;
          <br />
          La poesía está de primavera,
          <br />o la primavera se viste de poesía,
          <br />
          efímeras, fugaces,
          <br />
          por allí pasa la riqueza,
          <br />
          fugaces son los años,
          <br />
          los pájaros, la rosa, el verano.
          <br />
          &nbsp;
          <br />
          Miro el mundo circundante,
          <br />
          me descubro conectado
          <br />
          con todo el paisaje,
          <br />
          lujuria de este tiempo.
          <br />
          <br />
          Es primavera.
        </p>
      </center>
      <div class="autores">
        <div class="autor">
          <h6>Eduardo Ceballos.</h6>
          <img
            src="https://res.cloudinary.com/lagexpress/image/upload/f_auto,q_auto,r_max,q_100,w_100,h_100//strapi/Foto_a321272643"
            alt=""
          />
        </div>
      </div>
      <CommentsFB />
    </Layout>
  )
}

export default Poemas
